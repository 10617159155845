import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
// import "../../../../App.css";
import TipTap from '../../TipTapTable';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import HTMLReactParser from 'html-react-parser';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const TBody = ({
  moduleData,
  isView,
  setModuleData,
}: IProps) => {
  
const [render,setRender]=useState(false);
  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      nc_minutes: desc
    }));
  };
  const handleInputChange = (name: string, value: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
     <thead>
     <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['FRM-QAS-A-009 ', '00', '01-04-2023']}
        heading="Non-Conformity Report"
      />
       </thead>
       <>
      
      <tbody>


      <tr style={{border:'none'}}>
        <th colSpan={2} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             NCR No
          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="ncr_no"
              type="text"
              value={moduleData?.ncr_no}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      

      </tr>
      <tr style={{ border: 'none' }}>
      <th align='center' colSpan={5} style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              // marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            TO BE FILLED BY THE AUDITOR 
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>
      </tr>
      <tr style={{ border: 'none' }}>
      <th
          colSpan={1}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
           AUDIT DATE :
          </div>
        </th>

        <th
          colSpan={1}
          align="left"
          style={{ border:'none' }}
        >
          {isView ? (
            formatDate(moduleData.audit_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.audit_date)}
              onChange={(date) => {
                moduleData.audit_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             Auditor
          </div>
        </th>
        <td colSpan={1} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditor"
              type="text"
              value={moduleData?.auditor}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             Process Owner
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="process_owner"
              type="text"
              value={moduleData?.process_owner}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      </tr>
      <tr style={{border:'none'}}>
        <th colSpan={2} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
             Shift
          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="shift"
              type="text"
              value={moduleData?.shift}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      

      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           Checklist-Section & Requirment:
          </div>
        </th>
        
      </tr>
      <tr style={{border:'none'}}>
        <th colSpan={2} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          Checklist No.
          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="checklist_no"
              type="text"
              value={moduleData?.checklist_no}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      

      </tr>
     
      </tbody>
      </>
    </>
  );
};

export default TBody;
