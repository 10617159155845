import moment from 'moment';

export const processes = [
  {
    date_audit: '',
    proc_audit: '',
    auditor: '',
    auditee: '',
    // to_be_verified: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  no_of_rows: 5,
  details_of_nc: '<h1></h1>',
  details_of_desc: '<h1></h1>',
  auditor_sign:'',
  dept_rep_sign:'',
  correction: '<h1></h1>',
  root_cause: '<h1></h1>',
  corr_action: '<h1></h1>',
  scope:'',
  target_date:moment(),
  target_date_date:moment(),
  hod_sign:'',
  corr_action_obj:'<h1></h1>',
  follow_up_details:'',
  actual_date: moment(),
  actual_date_date: moment(),
  auditor_sign2:'',
  nc_closed:'',
  nc_not_closed:'',
  mr_sign:'',
  mr_date: moment(),
  verif: '<h1></h1>',
  remarks: '',
  // verif: '',
  // remarks: '',
  nc_minutes:'<table style="width: 1800px"><colgroup><col style="width: 300px"><col style="width: 300px"><col style="width: 300px"><col style="width: 300px"><col style="width: 300px"><col style="width: 300px"><col style="width: 300px"></colgroup><tbody><tr><th colspan="1" rowspan="1" colwidth="300"><p>S.NO.</p></th><th colspan="1" rowspan="1" colwidth="300"><p>Document / Record Name</p></th><th colspan="1" rowspan="1" colwidth="300"><p>Doc / Rec. format Number </p></th><th colspan="1" rowspan="1" colwidth="300"><p>Rev No</p></th><th colspan="1" rowspan="1" colwidth="300"><p>Rev date   </p></th><th colspan="1" rowspan="1" colwidth="172"><p>ANY OTHER REMARKS</p></th></tr><tr><td colspan="1" rowspan="1" colwidth="58"><p></p></td><td colspan="1" rowspan="1" colwidth="223"><p></p></td><td colspan="1" rowspan="1" colwidth="283"><p></p></td><td colspan="1" rowspan="1" colwidth="258"><p></p></td><td colspan="1" rowspan="1" colwidth="221"><p></p></td><td colspan="1" rowspan="1" colwidth="177"><p></p></td></tr></tbody></table>',
  module_date: moment(),
  mrm_date: null,
  processes: Array.from({ length: 5 }, () => ({ ...processes[0] })),
};
