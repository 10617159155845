import { Production } from '../pages/ProductionPage';
import {
  InhouseTiles,
  ProcessRejectionTiles,
} from '../pages/processRejectionSheet';
import {
  DailyProduction,
  CreateDailyProduction,
  CombinedView
} from '../pages/ProductionPage/dailyProductionRotor';
import {
  ProcessRejectionList,
  ProcessRejectionCreate,
} from '../pages/processRejectionSheet/processRejection';
import {
  DailyProductionStrokes,
  CreateDailyProductionStrokes,
} from '../pages/ProductionPage/dailyProductionStrokes';
import {
  DailyProductionRedBin,
  CreateDailyProductionRedBin,
} from '../pages/ProductionPage/dailyProductionRedBin';
import {
  HourlyProductionReportList,
  CreateHourlyProductionReport,
} from '../pages/ProductionPage/HourlyProductionReport';

import {
  DailyRejectionRotorList,
  CreateDailyRejectionRotor,
} from '../pages/ProductionPage/dailyProductionRotorDailyRejection';
import PDCSection from '../pages/ProductionPage/PDCSection';
import PressShop from '../pages/ProductionPage/PressShop';
import RotorSection from '../pages/ProductionPage/RotorSection';
import { Profile } from '../pages/profile';
import CNCSection from '../pages/ProductionPage/CNCSection';
import {
  CNCRedBinList,
  CreateViewCNCRedBin,
} from '../pages/ProductionPage/redBinCNC';

import {
  PressShopRedBinList,
  CreateViewPressShopRedBin,
} from '../pages/ProductionPage/redBinPressShop';
import {
  RotorSectionRedBinList,
  CreateViewRotorSectionRedBin,
} from '../pages/ProductionPage/redBinRotorSection';
import {
  DailyHourlyCNC,
  CreateDailyHourlyCNC,
} from '../pages/ProductionPage/dailyProdCNCMachining';
import UnderConstructionPage from '../underconstruction-page';
import { ClauseUploadList } from '../pages/clauseUpload';
import {
  MasterProcedureNewUpload,
  PdcHome,
  PressShopHome,
  RotorHome,
  WorkInstructionsUpload,
} from '../pages/clause3';
// clause 8 modules
import {
  AbnormalityLogBook,
  CreateAbnormalityLogBook,
} from '../pages/abnormalityLogBook';
import {
  BopTableList,
  BopCreate,
  PirTiles,
  MigList,
  MigCreate,
  CreateWeldShopPir,
  WeldShopPirList,
  PressShopList,
  PressShopCreate,
  PlatingPowderCoatingList,
  PlatingPowderCoatingCreate,
} from '../pages/pir';
import { VendirList } from '../pages/MasterListOfVendor';
import {
  ProcessControlStandardCreate,
  ProcessControlStandardList,
} from '../pages/processControlStandard';
import {
  CreateForMSetup,
  CreateForMSummary,
  SummaryTableList,
  CreateHoldCard,
  TrainingRecord,
} from '../pages/4m';
import {
  SpcPlanTiles,
  SpcPlanPlanList,
  SpcPlanPlanCreate,
  SpcPlanChecklistList,
  MasterListofSpcPlan,
} from '../pages/spcPlan';
import { CreateEditMsa, MSAList } from '../pages/MSA';
import { ScrapNoteList, CreateScrapNote } from '../pages/scrapNote';
import {
  ChecklistList,
  Clause15,
  InstrumentMasterList,
  MsaChecklistList,
  MsaPlanCreate,
  MsaPlanList,
  PlanCreate,
  PlanList,
  TilesCallibration,
  TilesMsa,
} from '../pages/clause15';

import {
  CreateInpsectionStandard,
  InspectionStandardList,
  RIDeparmentHome,
  RIPart,
  SupplierChecklist,
  SupplierPlanCreate,
  SupplierPlanList,
  SupplierRatingCreate,
  SupplierRatingList,
  VendorList,
  IncomingPartMonitoringSheetList,
  IncomingPartMonitoringSheetCreate,
  RejectionReasonList,
  IncomingPartRejectionPPMGraph
  // CreateInpsectionStandard,
  // InspectionStandardList,
  // RiDocumentUpload,
  // RIPart,
  // SupplierDocument,
  // SupplierDocumentHome,
  // VendorList,
} from '../pages/RIDeparment';
import {
  InspectionReport,
  InspectionReportList,
} from '../pages/inspectionReportImperial';
import ProcessRejectionDashboardTabs from '../pages/processRejectionSheet/rejectionNoteDashboard/RejectionAnalysisDashboardTabs';
import ProcessRejectionDashBoardList from '../pages/processRejectionSheet/rejectionNoteDashboard/TableList';
import { SamplingStd } from '../pages/RIDeparment/samplingStd';
import {
  PrevantiveMaintenanceChecklistCreate,
  PrevantiveMaintenanceChecklistList,
  PrevantiveMaintenancePlanCreate,
  PrevantiveMaintenancePlanList,
  PrevantiveMaintenanceTiles,
} from '../pages/prevantiveMaintenance';
import { MushinHelpDesk } from '../pages/Mushin_Help_Desk';

import ToolsList from '../pages/masterlisofTools/List';
import { CriticalSparePartsList } from '../pages/masterListOfCriticalSpareParts';
import ToolsHistory from '../pages/masterlisofTools/toolsHistory/CreateToolHistory';
import {
  ToolChecklistFormat,
  ToolChecklistTable,
  ToolChecklistView,
  ToolStatusHome,
} from '../pages/masterlisofTools/statusAndCheckSheet/Index';
import { BreakDownSlipList, CreateBreakDownSlip } from '../pages/breakDownSlip';
import {
  MaintenanceLogTiles,
  BreakDownFinalList,
  MtbfList,
  MtbfCreate,
  MttrList,
  MttrCreate,
  MachineBreakDownReord,
  MachineBreakDownAnalysis,
  MasterListOfGauges,
  ToolBreakDownRecord,
  CriticalSparePartsStoreList,
} from '../pages/clause10Page';
import {
  ProcessAndProductAuditPlanList,
  ProcessAndProductAuditPlanCreate,
  ProcessAndProductAuditChecklistList,
  ProcessAndProductAuditChecklistCreate,
  ProcessAndProductAuditCreateSummarySheet,
  MasterListOfProcess,
  ProcessAuditPlan_Tiles,
  ProcessAuditPlan_PlanList,
  ProcessAuditPlan_PlanCreate,
  ProcessAuditPlan_Status,
  ProcessAuditPlan_Checklist,
  ProcessAuditPlan_StatusCheckList,
  ProcessAuditPlan_ChecklistCreate,
  ProductAuditPlanTiles,
  ProductAuditPlanChecklistList,
  ProductAuditPlanCreate,
  ProductAuditPlanList,
  ProductAuditReportTabs,
  ProductAuditReportList,
  ProductAuditPlanOldChecklistCreate,
  ProductAuditCreateSummarySheet,
  MispPartTable,
  MispStandardsCreate,
} from '../pages/clause6';
import {
  GaugeMaintenanceTiles,
  GaugeMaintenancePlanList,
  GaugeMaintenancePlanCreate,
  GaugeMaintenanceStatus,
  GaugeMaintenanceChecklist,
  GaugeMaintenanceChecklistCreate,
  GaugeMaintenanceStatusCheckList,
} from '../pages/gaugeMaintenancePlan/index';

import {
  PrevantiveMaintenance_NEW_Tiles,
  PrevantiveMaintenance_NEW_PlanList,
  PrevantiveMaintenance_NEW_PlanCreate,
  PrevantiveMaintenance_NEW_Status,
  PrevantiveMaintenance_NEW_Checklist,
  PrevantiveMaintenance_NEW_ChecklistCreate,
  PrevantiveMaintenanceStatusCheckList,
} from '../pages/prevantiveMaintenance_new/index';
import { Management5S, Procedure5SUpload } from '../pages/clause9Page';
import {
  CreateMatrix,
  CreateNpdTimePlan,
  MatrixList,
  NpdPartList,
  TimePlanList,
  DevlopmentTrackerList,
} from '../pages/clause1';
import {
  FinalInspectionRejectionRecordsList,
  FinalInspectionRejectionRecordsCreate,
  MaterialInspectionRejectionRecordsList,
  MaterialInspectionRejectionRecordsCreate,
  FinalInspectionRejectionPlatingCreate,
  FinalInspectionRejectionPlatingList,
  FinalInspectionRejectionPowderCoatingList,
  FinalInspectionRejectionPowerCoatingCreate,
  FinalInspectionRecordsSupplierTiles,
  ListOfUploadPfd,
} from '../pages/clause4Page';
import {
  FacultyList,
  CreateEditTopicPaper,
  MaterListTniCategory,
  QuickViewTopicPaper,
  TNITiles,
  TniCsrPlanChecklistList,
  TniCsrPlanCreate,
  TniCsrPlanList,
  TniCsrPlanTiles,
  TniTopicList,
  TniTrainingPlanChecklistList,
  TniTrainingPlanCreate,
  TniTrainingPlanList,
  TniTrainingPlanTiles,
  TrainingCalenderTiles,
  TrainingTniHome,
  TniPlanList,
  TniPlanCreate,
  MaterListSkillCategory,
  SkillEvaluationHome,
  CreateEditTopicSkillPaper,
  ViewTopicSkillPaper,
  SkillMatrixCreate,
  SkillMatrixList,
} from '../pages/clause5page';
import EffectivenessTestHome from '../pages/clause5page/effectivenessTestPaper/EffectivenessTestHome';
import DownloadTestPaperHome from '../pages/clause5page/downloadTestPaper/DownloadTestPaperHome';
import QuestionPaperList from '../pages/questionPaper/List';
import CreateEditQuestionPaper from '../pages/questionPaper/Create';
import QuickViewQuestionPaper from '../pages/questionPaper/View';
import {
  RevalidationChecklist,
  RevalidationChecklistCreate,
  RevalidationPlanCreate,
  RevalidationPlanList,
  RevalidationStatus,
  RevalidationStatusCheckList,
  RevalidationTiles,
} from '../pages/clause6/revalidationPlan';
import ListOfProcedures from '../pages/listOfProcedures/listOfProcedures';
import { KaizenSubmissionList } from '../pages/kaizen';
import { ListOfPokaYoke } from '../pages/clause10Page/ListOfPokaYoke';
import { PokeYokeCheckSheet } from '../pages/clause10Page/pokeyokeChecksheet/Index';
import {
  FmeaFormatCreate,
  GenerateFmeaFormatCreate,
  GenerateFmeaFormatList,
} from '../pages/fmeaManagement';
import { CreateMachineMaintenanceCard } from '../pages/masterlistofMachine/Index';


// Dashboard //
import { PendingReportsTables, DashboardPage, OEEAnalysisDashboard , DCTable, MonthlyMachineBreakDownReord, MinorBreakdownTable, CombinedTableComponent} from '../pages/dashboard';
import DowntimeReasons from '../pages/ProductionPage/listofDowntimeReasons/DowntimeReasonsList';
import { CreateInternalAuditNcSummaryTracker } from '../pages/clause6/internalAuditNcSummaryTracker';

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}
// clause 5
const clause5 = [
  {
    path: 'effectivenessTestPaperHome',
    element: <EffectivenessTestHome />,
  },
  {
    path: 'downloadTestPaper',
    element: <DownloadTestPaperHome />,
  },
  {
    path: 'createTestPaper',
    element: <QuestionPaperList />,
  },
  {
    path: 'createEditQuestionPaper/:id?',
    element: <CreateEditQuestionPaper />,
  },
  {
    path: 'quick_view_questionpaper/:id',
    element: <QuickViewQuestionPaper />,
  },
  {
    path: 'masterlistoffaculty',
    element: <FacultyList />,
  },
  //tni
  {
    path: 'trainingTniHome',
    element: <TrainingTniHome />,
  },
  {
    path: 'tniTiles',
    element: <TNITiles />,
  },
  {
    path: 'masterlistofTniCategory',
    element: <MaterListTniCategory />,
  },
  {
    path: 'topicPaper/list',
    element: <TniTopicList />,
  },
  {
    path: 'createEditTopicPaper/:id?',
    element: <CreateEditTopicPaper />,
  },
  {
    path: 'quick_view_topic_paper/:id',
    element: <QuickViewTopicPaper />,
  },
  {
    path: 'trainingCalenderTiles',
    element: <TrainingCalenderTiles />,
  },
  //tni end
  //tni plan
  {
    path: 'tniPlanList',
    element: <TniPlanList />,
  },
  {
    path: 'tniPlanCreate/:id',
    element: <TniPlanCreate />,
  },
  //training plan
  {
    path: 'tniTrainingPlanTiles',
    element: <TniTrainingPlanTiles />,
  },
  {
    path: 'tniTrainingPlanList',
    element: <TniTrainingPlanList />,
  },
  {
    path: 'tniTrainingPlanCreate/:id',
    element: <TniTrainingPlanCreate />,
  },
  {
    path: 'tniTraningPlanChecklistList',
    element: <TniTrainingPlanChecklistList />,
  },
  //csr plan
  {
    path: 'tniCsrPlanTiles',
    element: <TniCsrPlanTiles />,
  },
  {
    path: 'tniCsrPlanList',
    element: <TniCsrPlanList />,
  },
  {
    path: 'tniCsrPlanCreate/:id',
    element: <TniCsrPlanCreate />,
  },
  {
    path: 'tniCsrPlanChecklistList',
    element: <TniCsrPlanChecklistList />,
  },
  //skill Evaluation
  {
    path: 'skillEvaluationHome',
    element: <SkillEvaluationHome />,
  },
  {
    path: 'skillEvaluationCategoryList',
    element: <MaterListSkillCategory />,
  },
  {
    path: 'createEditTopicSkillPaper/:categoryId?',
    element: <CreateEditTopicSkillPaper />,
  },
  {
    path: 'viewTopicSkillPaper/:categoryId',
    element: <ViewTopicSkillPaper />,
  },
  {
    path: 'skillMatrixList',
    element: <SkillMatrixList />,
  },
  {
    path: 'skillMatrixCreate/:id',
    element: <SkillMatrixCreate />,
  },
];
export const Clause6 = [
  {
    path: 'processAndProductAuditPlanList',
    element: <ProcessAndProductAuditPlanList />,
  },
  {
    path: 'processAndProductAuditPlanCreate/:id',
    element: <ProcessAndProductAuditPlanCreate />,
  },
  {
    path: 'processAndProductAuditChecklistList',
    element: <ProcessAndProductAuditChecklistList />,
  },
  {
    path: 'processAndProductAuditChecklistCreate/:moduleId?',
    element: <ProcessAndProductAuditChecklistCreate />,
  },
  {
    path: 'summarySheet/:id',
    element: <ProcessAndProductAuditCreateSummarySheet />,
  },
  {
    path: 'masterListOfProcess',
    element: <MasterListOfProcess />,
  },
  // process audit plan new
  {
    path: 'processAuditTiles',
    element: <ProcessAuditPlan_Tiles />,
  },
  {
    path: 'processAuditPlan_PlanList',
    element: <ProcessAuditPlan_PlanList />,
  },
  {
    path: 'processAuditPlan_PlanCreate/:id',
    element: <ProcessAuditPlan_PlanCreate />,
  },
  {
    path: 'processAuditPlan_Status',
    element: <ProcessAuditPlan_Status />,
  },
  {
    path: 'processAuditPlan_Checklist',
    element: <ProcessAuditPlan_Checklist />,
  },
  {
    path: 'processAuditPlan_ChecklistCreate/:id?',
    element: <ProcessAuditPlan_ChecklistCreate />,
  },
  {
    path: 'processAuditPlan_StatusCheckList/:id?',
    element: <ProcessAuditPlan_StatusCheckList />,
  },
  //product  audit plan old
  {
    path: 'productAuditPlanTiles',
    element: <ProductAuditPlanTiles />,
  },
  {
    path: 'productAuditPlanList',
    element: <ProductAuditPlanList />,
  },
  {
    path: 'productAuditPlanCreate/:id',
    element: <ProductAuditPlanCreate />,
  },
  {
    path: 'productAuditPlanChecklistList',
    element: <ProductAuditPlanChecklistList />,
  },
  {
    path: 'productAuditReportCreate/:partId/:id?',
    element: <ProductAuditReportTabs />,
  },
  {
    path: 'productAuditReportList/:partId',
    element: <ProductAuditReportList />,
  },
  {
    path: 'productAuditPlanOldChecklistCreate/:moduleId?',
    element: <ProductAuditPlanOldChecklistCreate />,
  },
  {
    path: 'productAuditSummarySheet/:id',
    element: <ProductAuditCreateSummarySheet />,
  },
  {
    path: 'internalAuditNcSummaryTracker/create/:id/:idx',
    element: <CreateInternalAuditNcSummaryTracker />,
  },
  //misp standard
  {
    path: 'mispPartTable',
    element: <MispPartTable />,
  },
  {
    path: 'mispStandardsCreate/:partId',
    element: <MispStandardsCreate />,
  },
];
// scrap Note

interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const other: routes[] = [
  {
    path: 'profile',
    element: <Profile />,
  },
  {
    path: 'pdc',
    element: <PDCSection />,
  },
  {
    path: 'rotorSection',
    element: <RotorSection />,
  },
  {
    path: 'pressShop',
    element: <PressShop />,
  },
  {
    path: 'cnc',
    element: <CNCSection />,
  },
];

export const processRejection = [
  {
    path: 'processRejection',
    element: <ProcessRejectionTiles />,
  },
  {
    path: 'inhouserejection/dashboardList',
    element: <InhouseTiles />,
  },
  {
    path: 'processRejection/dailyProductionList',
    element: <ProcessRejectionList />,
  },
  {
    path: 'createEditProcessRejection/:id?',
    element: <ProcessRejectionCreate />,
  },
  {
    path: 'processRejection/dashboard',
    element: <ProcessRejectionDashboardTabs />,
  },
  {
    path: 'processRejection/dashboardList',
    element: <ProcessRejectionDashBoardList />,
  },
];
export const productions = [
  {
    path: 'production',
    element: <Production />,
  },
  // {
  //   path: 'dailyProductionList',
  //   element: <DailyProduction />,
  // },

  // --------------------------- rotor section -------------------------------------------------------------------------
  // daily prod rotor report
  {
    path: 'rotorSection/dailyProductionList',
    element: <DailyProduction />,
  },
  {
    path: 'createEditDailyProduction/:id?',
    element: <CreateDailyProduction />,
  },

  // comibined view for production data
  {
    path: 'combinedView',
    element: <CombinedView />,
  },

  // daily rotor rejection report
  {
    path: 'rotorSection/dailyProductionRotorDailyRejectionList',
    element: <DailyRejectionRotorList />,
  },
  {
    path: 'createEditDailyProductionRotorDailyRejection/:id?',
    element: <CreateDailyRejectionRotor />,
  },
  // daily production redbin list (doubt it is to be deleted or not)
  // {
  //   path: 'rotorSection/dailyProductionRedBinList',
  //   element: <DailyRejectionRotorList />,
  // },
  // {
  //   path: 'createEditDailyProductionRotorDailyRejection/:id?',
  //   element: <CreateDailyRejectionRotor />,
  // },
  // redbin prashant
  {
    path: 'rotorSection/redbinRotorSection',
    element: <RotorSectionRedBinList />,
  },
  {
    path: 'rotorSection/createEditRedbinRotorSection/:id?',
    element: <CreateViewRotorSectionRedBin />,
  },

  // ----------------------------------end of rotor section -----------------------------------------------------------------------

  // -------------------------------------------pdc section -------------------------------------------------------------------------
  // hourly report
  {
    path: 'pdc/rotorHourlyProductionList',
    element: <HourlyProductionReportList />,
  },
  {
    path: 'createHourlyProductionReports/:id?',
    element: <CreateHourlyProductionReport />,
  },

  // prodRed bin pdc section
  {
    path: 'pdc/dailyProductionRedBinList',
    element: <DailyProductionRedBin />,
  },
  {
    path: 'createEditDailyProductionRedBin/:id?',
    element: <CreateDailyProductionRedBin />,
  },

  // ---------------------------------------------- press shop section ----------------------------------------------
  // dailyprod strokes
  {
    path: 'pressShop/dailyProductionStrokesList',
    element: <DailyProductionStrokes />,
  },
  {
    path: 'createEditDailyProductionStrokes/:id?',
    element: <CreateDailyProductionStrokes />,
  },

  {
    path: 'pressShop/redbinPressShop',
    element: <PressShopRedBinList />,
  },
  {
    path: 'pressShop/createEditRedbinPressShop/:id?',
    element: <CreateViewPressShopRedBin />,
  },

  // redbin

  // ---------------------------------------------- end of press shop section ----------------------------------------------

  // ------------------------------------------------- cnc secction --------------------------------------------------------

  // hourly prod cnc
  {
    path: 'cnc/dailyHourlyProdCNCList',
    element: <DailyHourlyCNC />,
  },
  {
    path: 'cnc/createEditDailyHourlyProdCNC/:id?',
    element: <CreateDailyHourlyCNC />,
  },
  // redbin cnc
  {
    path: 'cnc/redbinCNC',
    element: <CNCRedBinList />,
  },
  {
    path: 'cnc/createEditRedbinCNC/:id?',
    element: <CreateViewCNCRedBin />,
  },
];

const clause3ForM = [
  {
    path: '4mPdc',
    element: <PdcHome />,
  },
  {
    path: '4mPressShop',
    element: <PressShopHome />,
  },
  {
    path: '4mRotor',
    element: <RotorHome />,
  },
  {
    path: 'masterProcedureNewUpload',
    element: <MasterProcedureNewUpload />,
  },
  {
    path: 'workInstructionsUpload',
    element: <WorkInstructionsUpload />,
  },
];

const clause8Modules = [
  // abnormality Log book
  {
    path: 'abnormalityLogBookList',
    element: <AbnormalityLogBook />,
  },
  {
    path: 'createAbnormalityLogBook/:id?',
    element: <CreateAbnormalityLogBook />,
  },
];

const pir = [
  {
    path: 'pirtiles',
    element: <PirTiles />,
  },
  {
    path: 'bopcreate/:partId/:pirId?',
    element: <BopCreate />,
  },
  {
    path: 'BopTableList',
    element: <BopTableList />,
  },
  {
    path: 'migList',
    element: <MigList />,
  },
  {
    path: 'migCreate/:partId/:pirId?',
    element: <MigCreate />,
  },
  // weld shop

  {
    path: 'weldShop/PirList',
    element: <WeldShopPirList />,
  },
  {
    path: 'weldshopcreate/:partId/:pirId?',
    element: <CreateWeldShopPir />,
  },
  // press shop
  {
    path: 'pressShop/PirList',
    element: <PressShopList />,
  },
  {
    path: 'pressShop/createPir/:partId/:pirId?',
    element: <PressShopCreate />,
  },
  // plating and powdder coating
  {
    path: 'platingAndPowderCoating/PirList',
    element: <PlatingPowderCoatingList />,
  },
  {
    path: 'platingAndPowderCoating/createPir/:partId/:pirId?',
    element: <PlatingPowderCoatingCreate />,
  },
  {
    path: 'platinglist',
    element: <UnderConstructionPage />,
  },
];
const forMSummary = [
  {
    path: 'create4mSummary',
    element: <CreateForMSummary />,
  },
  {
    path: '4mSummaryList',
    element: <SummaryTableList />,
  },
  {
    path: 'create4mSetup/:partId/:rowId/:setupId?',
    element: <CreateForMSetup />,
  },
  {
    path: 'createHoldCard/:id?',
    element: <CreateHoldCard />,
  },
];

const SpcPlan = [
  {
    path: 'spcPlanTiles',
    element: <SpcPlanTiles />,
  },
  {
    path: 'spcPlanPlanList',
    element: <SpcPlanPlanList />,
  },
  {
    path: 'spcPlanPlanCreate/:id',
    element: <SpcPlanPlanCreate />,
  },
  {
    path: 'spcPlanChecklistList',
    element: <SpcPlanChecklistList />,
  },
  {
    path: 'MasterListofspcPlan',
    element: <MasterListofSpcPlan />,
  },
];
const MSA = [
  {
    path: 'MSAList',
    element: <MSAList />,
  },
  {
    path: 'CreateEditMsa/:partId/:id?',
    element: <CreateEditMsa />,
  },
];

// inspection qlty std ri dept
const inspectionStandard = [
  {
    path: 'inspectionStandardList',
    element: <InspectionStandardList />,
  },
  {
    path: 'inspectionStandard/:inspectionStandardId?',
    element: <CreateInpsectionStandard />,
  },
];

const instrumentClause15 = [
  {
    path: 'instrumentplanlist',
    element: <PlanList />,
  },
  {
    path: 'instrumentplancreate/:id',
    element: <PlanCreate />,
  },
  {
    path: 'msaplanlist',
    element: <MsaPlanList />,
  },
  {
    path: 'msaplancreate/:id',
    element: <MsaPlanCreate />,
  },
  {
    path: 'msachecklistList',
    element: <MsaChecklistList />,
  },
  {
    path: 'instrumentMasterlist',
    element: <InstrumentMasterList />,
  },
  {
    path: 'checklistList',
    element: <ChecklistList />,
  },
];
const inspectionReportRoute = [
  {
    path: 'inspectionReportList',
    element: <InspectionReportList />,
  },
  {
    path: 'inspectionReport/:partId/:inspectionReportId?',
    element: <InspectionReport />,
  },
];
const clause10 = [
  {
    path: 'maintenanceLog',
    element: <MaintenanceLogTiles />,
  },
  {
    path: 'breakDownLogBook',
    element: <BreakDownFinalList />,
  },
  {
    path: 'mtbf',
    element: <MtbfList />,
  },
  {
    path: 'mtbfCreate/:id',
    element: <MtbfCreate />,
  },
  {
    path: 'mttr',
    element: <MttrList />,
  },
  {
    path: 'mttrCreate/:id',
    element: <MttrCreate />,
  },
];
const prevantiveMaintenance = [
  {
    path: 'prevantiveMaintenanceTiles',
    element: <PrevantiveMaintenanceTiles />,
  },
  {
    path: 'prevantiveMaintenancePlanList',
    element: <PrevantiveMaintenancePlanList />,
  },
  {
    path: 'prevantiveMaintenacePlanCreate/:id',
    element: <PrevantiveMaintenancePlanCreate />,
  },
  {
    path: 'prevantiveMaintenanceChecklistList',
    element: <PrevantiveMaintenanceChecklistList />,
  },

  {
    path: 'prevantiveMaintenaceChecklistCreate/:moduleId?',
    element: <PrevantiveMaintenanceChecklistCreate />,
  },
  // gauge plan
  {
    path: 'gaugeMaintenanceTiles',
    element: <GaugeMaintenanceTiles />,
  },
  {
    path: 'gaugeMaintenancePlanList',
    element: <GaugeMaintenancePlanList />,
  },
  {
    path: 'gaugeMaintenancePlanCreate/:id',
    element: <GaugeMaintenancePlanCreate />,
  },
  {
    path: 'gaugeMaintenanceStatus',
    element: <GaugeMaintenanceStatus />,
  },
  {
    path: 'gaugeMaintenanceChecklist',
    element: <GaugeMaintenanceChecklist />,
  },
  {
    path: 'gaugeMaintenanceChecklistCreate/:id?',
    element: <GaugeMaintenanceChecklistCreate />,
  },
  {
    path: 'gaugeMaintenanceStatusCheckList/:id?',
    element: <GaugeMaintenanceStatusCheckList />,
  },

  // new plan
  {
    path: 'prevantiveMaintenance_NEW_Tiles',
    element: <PrevantiveMaintenance_NEW_Tiles />,
  },
  {
    path: 'prevantiveMaintenance_NEW_PlanList',
    element: <PrevantiveMaintenance_NEW_PlanList />,
  },
  {
    path: 'prevantiveMaintenance_NEW_PlanCreate/:id',
    element: <PrevantiveMaintenance_NEW_PlanCreate />,
  },
  {
    path: 'prevantiveMaintenance_NEW_Status',
    element: <PrevantiveMaintenance_NEW_Status />,
  },
  {
    path: 'prevantiveMaintenance_NEW_Checklist',
    element: <PrevantiveMaintenance_NEW_Checklist />,
  },
  {
    path: 'prevantiveMaintenance_NEW_ChecklistCreate/:id?',
    element: <PrevantiveMaintenance_NEW_ChecklistCreate />,
  },
  {
    path: 'prevantiveMaintenanceStatusCheckList/:id?',
    element: <PrevantiveMaintenanceStatusCheckList />,
  },
];

const revaditionPlan = [
  {
    path: 'revalidationPlanTiles',
    element: <RevalidationTiles />,
  },
  {
    path: 'revalidationPlanList',
    element: <RevalidationPlanList />,
  },
  {
    path: 'revalidationPlanCreate/:id',
    element: <RevalidationPlanCreate />,
  },
  {
    path: 'revalidationStatus',
    element: <RevalidationStatus />,
  },
  {
    path: 'revalidationChecklist',
    element: <RevalidationChecklist />,
  },
  {
    path: 'revalidationChecklistCreate/:id?',
    element: <RevalidationChecklistCreate />,
  },
  {
    path: 'revalidationStatusCheckList/:id?',
    element: <RevalidationStatusCheckList />,
  },
];
// Supplier Rating
const supplierRating = [
  {
    path: 'approvedSupplierList',
    element: <VendorList />,
  },
  // supplier rating in ridept
  {
    path: 'supplierRatingList/:supplierId',
    element: <SupplierRatingList />,
  },
  {
    path: 'supplierRatingCreate/:id',
    element: <SupplierRatingCreate />,
  },
];

const riDepartment = [
  {
    path: 'incomingPartMonitoringSheetList',
    element: <IncomingPartMonitoringSheetList />,
  },
  {
    path: 'createEditIncomingPartMonitoringSheet/:id?',
    element: <IncomingPartMonitoringSheetCreate />,
  },
  {
    path: 'rejectionReasonList',
    element: <RejectionReasonList />,
  },
  {
    path: 'incomingPartRejectionPPMGraph',
    element: <IncomingPartRejectionPPMGraph />,
  },
];
export const clause4 = [
  {
    path: 'finalInspectionRejectionRecordsList',
    element: <FinalInspectionRejectionRecordsList />,
  },
  {
    path: 'createEditFinalInspectionRejectionRecords/:id?',
    element: <FinalInspectionRejectionRecordsCreate />,
  },
  {
    path: 'materialInspectionRejectionRecordsList',
    element: <MaterialInspectionRejectionRecordsList />,
  },
  {
    path: 'createEditMaterialInspectionRejectionRecords/:id?',
    element: <MaterialInspectionRejectionRecordsCreate />,
  },
  {
    path: 'finalInspectionRejectionRecordsSuppierTiles',
    element: <FinalInspectionRecordsSupplierTiles />,
  },
  {
    path: 'finalInspectionRejectionRecordsPlatingList',
    element: <FinalInspectionRejectionPlatingList />,
  },
  {
    path: 'createEditFinalInspectionRejectionPlating/:id?',
    element: <FinalInspectionRejectionPlatingCreate />,
  },
  {
    path: 'finalInspectionRejectionRecordsPowderCoatingList',
    element: <FinalInspectionRejectionPowderCoatingList />,
  },
  {
    path: 'createEditFinalInspectionRejectionPowderCoating/:id?',
    element: <FinalInspectionRejectionPowerCoatingCreate />,
  },
  {
    path: 'listOfUploadPfd',
    element: <ListOfUploadPfd />,
  },
];
export const clause9 = [
  {
    path: 'management5s',
    element: <Management5S />,
  },
  {
    path: 'procedure5SUpload',
    element: <Procedure5SUpload />,
  },
];
export const clause1 = [
  {
    path: 'npdMatrixList',
    element: <MatrixList />,
  },
  {
    path: 'createNpdMatrix/:partId',
    element: <CreateMatrix />,
  },
  {
    path: 'npdTimePlanList',
    element: <TimePlanList />,
  },
  {
    path: 'createNpdTimePlan/:partId',
    element: <CreateNpdTimePlan />,
  },
  {
    path: 'npdPartList',
    element: <NpdPartList />,
  },
  {
    path: 'devlopmentTracker',
    element: <DevlopmentTrackerList />,
  },
];
const fmea = [
  {
    path: 'createFmeaFormat/:partId',
    element: <FmeaFormatCreate />,
  },
  {
    path: 'generateFmeaCreate/:id',
    element: <GenerateFmeaFormatCreate />,
  },
  {
    path: 'generateFmeaFormatList/:partId',
    element: <GenerateFmeaFormatList />,
  },
];


const dashboard = [
  {
    path: 'dashboardData',
    element: <PendingReportsTables />
  },
  {
    path: 'DashboardPage',
    element: <DashboardPage />
  },
  {
    path: 'OEEAnalysis',
    element: <OEEAnalysisDashboard />
  },
  {
    path: 'DCTable',
    element: <DCTable />
  },
  {
    path: 'downTimeReasons',
    element: <DowntimeReasons />
  },
  {
    path: 'monthlyMachineBreakDownReord',
    element: <MonthlyMachineBreakDownReord />,
  },
  {
    path: 'minorBreakdownTable',
    element: <MinorBreakdownTable />,
  },
  {
    path: 'combinedTable',
    element: <CombinedTableComponent />,
  },
];

export const IndexCommon: routes[] = [
  {
    path: 'riDepartment',
    element: <RIDeparmentHome />,
  },
  {
    path: 'riPart',
    element: <RIPart />,
  },
  {
    path: 'samplingStandard',
    element: <SamplingStd />,
  },
  {
    path: 'clauseUploadList',
    element: <ClauseUploadList />,
  },
  {
    path: 'masterListOfVendor',
    element: <VendirList />,
  },
  // tools and critical spare parts
  // machine and tools
  {
    path: 'masterlistofTools',
    element: <ToolsList />,
  },
  {
    path: 'toolsHistory/:id',
    element: <ToolsHistory />,
  },
  {
    path: 'tool/status/home/:toolId',
    element: <ToolStatusHome />,
  },
  {
    path: 'tool/status/checklist/format/:toolId',
    element: <ToolChecklistFormat />,
  },
  {
    path: 'tool/status/checklist/list/:toolId',
    element: <ToolChecklistTable />,
  },
  {
    path: 'tool/status/checklist/create/:id?',
    element: <ToolChecklistView />,
  },
  {
    path: 'machineMaintenanceHistory/:id',
    element: <CreateMachineMaintenanceCard />,
  },
  {
    path: 'masterListOfCriticalSparePart',
    element: <CriticalSparePartsList />,
  },
  {
    path: 'masterListOfCriticalSparePartStore',
    element: <CriticalSparePartsStoreList />,
  },
  {
    path: 'processControlStandardList/:partId',
    element: <ProcessControlStandardList />,
  },
  {
    path: 'processControlStandardCreate/:partId/:id?',
    element: <ProcessControlStandardCreate />,
  },
  {
    path: 'supplierplanlist',
    element: <SupplierPlanList />,
  },
  {
    path: 'supplierplancreate/:id',
    element: <SupplierPlanCreate />,
  },
  {
    path: 'supplierchecklist',
    element: <SupplierChecklist />,
  },

  // scrap note
  {
    path: 'scrapNoteList',
    element: <ScrapNoteList />,
  },
  {
    path: 'createEditScrapNote/:id?',
    element: <CreateScrapNote />,
  },
  {
    path: 'clause15',
    element: <Clause15 />,
  },
  {
    path: 'tilesCallibrationplan',
    element: <TilesCallibration />,
  },
  {
    path: 'tilesMsaplan',
    element: <TilesMsa />,
  },

  //Mushin Help Desk
  {
    path: 'mushinHelpDesk',
    element: <MushinHelpDesk />,
  },

  // break down slip
  {
    path: 'breakDownSlipList',
    element: <BreakDownSlipList />,
  },
  {
    path: 'createEditBreakDownSlip/:id?',
    element: <CreateBreakDownSlip />,
  },
  {
    path: 'trainingRecord/:id?',
    element: <TrainingRecord />,
  },

  // clause 10 breadown machine
  {
    path: 'machineBreakdownRecord',
    element: <MachineBreakDownReord />,
  },
  {
    path: 'machineBreakdownAnalysis/:id',
    element: <MachineBreakDownAnalysis />,
  },
  {
    path: 'gauges/list',
    element: <MasterListOfGauges />,
  },
  {
    path: 'toolBreakdownRecord',
    element: <ToolBreakDownRecord />,
  },

  {
    path: 'company_roles_responsibilities_listOfProcedures',
    element: <ListOfProcedures />,
  },
  {
    path: 'kaizenSubmissionList',
    element: <KaizenSubmissionList />,
  },
  {
    path: 'listOfPokaYoke',
    element: <ListOfPokaYoke />,
  },
  {
    path: 'pokeyokeChecksheet',
    element: <PokeYokeCheckSheet />,
  },
  ...clause3ForM,
  ...productions,
  ...processRejection,
  ...clause8Modules,
  ...other,
  ...pir,
  ...forMSummary,
  ...SpcPlan,
  ...MSA,
  ...instrumentClause15,
  ...inspectionStandard,
  ...inspectionReportRoute,
  ...prevantiveMaintenance,
  ...supplierRating,
  ...clause1,
  ...clause5,
  ...clause10,
  ...Clause6,
  ...clause9,
  ...riDepartment,
  ...clause4,
  ...revaditionPlan,
  ...fmea,
  ...dashboard,
];
