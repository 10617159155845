import { useState } from 'react';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../../components/common/useConfirm';
import moment from 'moment';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
  setTBodyRender: any;
  updateModuleData: any;
}
const TBodyRight = ({
  item,
  isView,
  index,
  allmonth,
  setTBodyRender,
  updateModuleData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'ADD',
    'Are you sure you want to Change Plan?',
  );
  const [DialogRemoveCheck, setDialogRemoveCheck] = useConfirm(
    'Remove',
    'Are you sure you want remove you cant roll back?',
  );

  //  1 double click -> plan 2 | click double -> replan | 3 double click -> nothing
  const stateTransitions: any = {
    // [current p, current r] => [next p, next r]
    'false,false': { p: true, r: false }, //first p
    'true,false': { p: false, r: true }, //second R
    'false,true': { p: false, r: false }, //third remove
  };

  const handleDoubleClick = async (event: any, month: string) => {
    if (event.detail === 2) {
      const ans = await setDialogisCheck();
      if (!ans) return;
      const currentStateKey = `${item[month].p},${item[month].r}`;
      const nextState = stateTransitions[currentStateKey];

      if (nextState) {
        item[month].p = nextState.p;
        item[month].r = nextState.r;
        item[month].created_date = null;
        setTBodyRender((prev: any) => !prev);
      }
    }
  };

  const yearRange = useQuery('yearRange');
  const [startYear, endYear] = yearRange ? yearRange.split('-') : [2022, 2023];



  // const handleDoubleClick = async (event: any, month: string) => {
  //   if (event.detail === 2) {
  //     const ans = await setDialogisCheck();
  //     if (!ans) return;

  //     // Determine the new state for `p`
  //     let newP = '';
  //     if (item[month].p === '') {
  //       newP = PlanType.isPlan;
  //     } else if (item[month].p === PlanType.isPlan) {
  //       newP = PlanType.isReplanPlan;
  //     }

  //     updateModuleData(index, month, { p: newP });
  //   }
  // };
  const handleDateClick = async (date: any, month: any, idx: any) => {
    if (date && moment(date).isValid()) {
      console.log("date is ",date);
      updateModuleData(index, month, { p: true, created_date: date });
    } else {
      delete item[month].doneDate;
      updateModuleData(index, month, {
        p: false,
        created_date: date,
        isTask: false,
      });
    }
  };
  const getLastDayOfMonth = (year: number, month: number) => {
    return moment(new Date(year, month, 0)).date();
  };

  const allNewMonth = [
  
    ['april', 4, startYear],
    ['may', 5, startYear],
    ['june', 6, startYear],
    ['july', 7, startYear],
    ['august', 8, startYear],
    ['september', 9, startYear],
    ['october', 10, startYear],
    ['november', 11, startYear],
    ['december', 12, startYear],
    ['january', 1, endYear],
    ['february', 2, endYear ],
    ['march', 3, endYear],
  ];

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
            minWidth: '100px',
          }}
          onClick={(e) => handleDoubleClick(e, month)}
        >
          <InteractiveIcon
            width={'100%'}
            isYellow={item[month].p}
            isBlue={item[month].r}
          >
            {item[month].p && 'P'}
            {item[month].r && 'R'}
          </InteractiveIcon>
        </td>
      ))}
      {/* {allNewMonth.map((month: any) => (
        // console.log()
        <td
          style={{
            padding: '0px',
            margin: '0px',
            backgroundColor: `${item[month[0]]?.created_date ? '#ffd43b' : '#f1f3f5'}`,
            // backgroundColor: `${item[month[0]]?.p ? '#ffd43b' : '#f1f3f5'}`,
          }}
        >
          <DatePickerMui
            label=""
            sx={{
              width: '150px',
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            minDate={moment(`${month[2]}-${month[1]}-1`, 'YYYY-MM-DD')}
            maxDate={moment(`${month[2]}-${month[1]}-${getLastDayOfMonth(
              month[2],
              month[1]
            )}`, 'YYYY-MM-DD')}
            value={moment(item[month[0]]?.created_date || null)}
            onChange={(date: any) => {
              handleDateClick(date, month[0], index);
            }}
          />
        </td>
      ))} */}
      <DialogisCheck isSubmitContent={false} />
      <DialogRemoveCheck isSubmitContent={false} />
    </>
  );
};

export default TBodyRight;
