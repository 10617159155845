import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
// import "../../../../App.css";
import TipTap from '../../TipTapTable';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import HTMLReactParser from 'html-react-parser';
import { TableCompHead } from '../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const THead2 = ({
  moduleData,
  isView,
  setModuleData,
}: IProps) => {
  const [render, setRender] = useState(false);


  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      nc_minutes: desc
    }));
  };
  const handleInputChange = (name: string, value: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
    
       <>
      
      <tbody>
      <tr style={{ border: 'none' }}>
      <th colSpan={5} align='center' style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            AUDITEE
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
      <th colSpan={7} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
Corrective action Objective Evidence Verification details:</div>
     </th>
      </tr>
      <tr style={{border:'none'}}>
        {isView ? (
          <td align='center' colSpan={7} style={{border:'none'}}>
            <div className='tiptaptable' style={{textAlign:'center'}}>
              {HTMLReactParser(moduleData.nc_minutes)}
            </div>
          </td>
        ) : (
          <td align='center' colSpan={7} style={{border:'none', textAlign:'center'}}>
            <div  className='tiptaptable' style={{columnSpan:'all', textAlign:'center'}}>
              <TipTap setDesc={handleSetDesc} initialContent={moduleData.nc_minutes} />
            </div>
          </td>
        )}
      </tr>

      <tr style={{ border: 'none' }}>
      <th colSpan={5} align='center' style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              // marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            AUDITOR
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={3} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
            Follow up Audit Details: (Verified for implementation and effectiveness)
          </div>
        </th>
       

        <th
          colSpan={4}
          align="left"
          style={{ border: 'none' }}
        >
         <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="follow_up_details"
              type="text"
              value={moduleData?.follow_up_details}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
        </th>
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
            Actual Date of Implementation:
          </div>
        </th>
        <th
          colSpan={1}
          style={{
            border: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>

        <th
          colSpan={1}
          align="left"
          style={{  border: 'none' }}
        >
          {isView ? (
            formatDate(moduleData.actual_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.actual_date)}
              onChange={(date) => {
                moduleData.actual_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
      <th
          colSpan={2}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            DATE :
          </div>
        </th>

        <th
          colSpan={2}
          align="left"
          style={{ border:'none' }}
        >
          {isView ? (
            formatDate(moduleData.actual_date_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.actual_date_date)}
              onChange={(date) => {
                moduleData.actual_date_date = date;
                // setRender((prev) => !prev);
              }}
            />
          )}
        </th>

        
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          Auditor Sign
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditor_sign2"
              type="text"
              value={moduleData?.auditor_sign2}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      </tr>
     
      <tr style={{ border: 'none' }}>
      <th colSpan={5} align='center' style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              // marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            MR
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>
      </tr>

      <tr>
      <th
          colSpan={2}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            NC Closed
          </div>
        </th>

        <th
          colSpan={2}
          align="left"
          style={{ border:'none' }}
        >
         <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="nc_closed"
              type="text"
              value={moduleData?.nc_closed}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
        </th>

        
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          NC Not Closed
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="nc_not_closed"
              type="text"
              value={moduleData?.nc_not_closed}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      </tr>
      <tr>
      <th
          colSpan={2}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            MR Sign
          </div>
        </th>

        <th
          colSpan={2}
          align="left"
          style={{ border:'none' }}
        >
         <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="mr_sign"
              type="text"
              value={moduleData?.mr_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
        </th>

        
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          Date 
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
        {isView ? (
            formatDate(moduleData.mr_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.mr_date)}
              onChange={(date) => {
                moduleData.mr_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
          </td>
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{  border :'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Remarks
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            border: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
             <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="remarks"
              type="text"
              value={moduleData?.remarks}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
           
          </div>
        </th>

      </tr>
    
      </tbody>
      </>
    </>
  );
};

export default THead2;
