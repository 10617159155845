import moment from 'moment';
import { useEffect, useState } from 'react';
import { PersitedDatePicker, TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import axios from 'axios';
import { apiConstant } from '../../constant';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  setModuleData: any;
  setFilteredSummary: any;
  isView: boolean;
}

const THead = ({ moduleData, isView ,setModuleData,setFilteredSummary}: IProps) => {
  const [render, setRender] = useState(false);
  // const [filteredSummary, setFilteredSummary] = useState([]); // For filtered display

  const { id } = useParams();
  const date = useQuery('date');

 
  // useEffect(() => {
  //   axios
  //     .get(`/api/${apiConstant.getPlanByid}/${id}`)
  //     .then((res) => {
  //       if (res?.data) {
  //         const data = { ...res.data };
          
  //         const startOfMonth = moment(date).startOf('month').toISOString();
  //         const endOfMonth = moment(date).endOf('month').toISOString();

  //         const filteredSummary = data?.summary?.filter((item: any) => {
  //           const auditDate = moment(item?.audit_date).toISOString();
  //           return auditDate >= startOfMonth && auditDate <= endOfMonth;
  //         });

  //         setModuleData((prevData: any) => ({
  //           ...prevData,
  //           summary: filteredSummary,
  //           // ...data,
  //         }));
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // }, [date, id, setModuleData]);


  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={3}
        labels={['Doc. No:', 'Rev. No : ', 'Eff. Date:']}
        values={['RM-QAS-A-010', '00', '01-04-2023']}
        heading="Process & Product Audit Summary Sheet"
      />
      <tr>
        <th colSpan={3} align="left">
          {/* Month : */}
          {/* <DatePickerMui
            label=""
            format={'MM-YYYY'}
            onChange={(date) => {
              // moduleData.month = date;
              currentMonth = date;
              // setRender((prev) => !prev);
            }}
            // onChange={(date) => {
            //   // Properly update the moduleData state using setModuleData
            //   setModuleData((prevData: any) => ({
            //     ...prevData,
            //     month: date,
            //   }));
            //   setRender((prev) => !prev); // Trigger re-render if necessary
            // }}
            views={['month', 'year']}
            value={moment(moduleData.month || null)}
          /> */}
           {/* <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label={`Search by month`}
          views={['year', 'month']}
        />
      </Box> */}
        </th>
        <th colSpan={8}></th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.No',
          'Audit Date',
          'Part Name',
          'Part No.',
          'Conforming / Not Conforming(NC)',
          'Total No. of Ncs.',
          'NC Status',
          'NC Closing Date',
          'Audit Status',
          'NC REPORT',
          'Action'
        ].map((head, index) => (
          <th
            key={head}
            colSpan={index === 6 ? 2 : 1}
            rowSpan={index === 6 ? 1 : 2}
            style={
              index === 2
                ? { minWidth: '300px', textTransform: 'uppercase' }
                : { minWidth: '', textTransform: 'uppercase' }
            }
          >
            {head}
          </th>
        ))}
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {['Open', 'Close'].map((head, index) => (
          <th key={head + index} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
