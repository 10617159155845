import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { Checkbox } from '@mui/material';
// import "../../../../App.css";
import TipTap from '../../TipTap';
import { parseUrl } from 'query-string/base';
import HTMLReactParser from 'html-react-parser';
import { TableInput } from '../../../../../components/common';
interface IProps {
  moduleData: any;
  isView: boolean;
  setModuleData: any;
}

const THead = ({ moduleData, isView, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const [agenda, setAgenda] = useState('');
  const [invitees, setInvitees] = useState('');

  const handleSetDesc = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      details_of_nc: desc,
    }));
  };
  const handleSetDescObj = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      details_of_desc: desc,
    }));
  };

  const handleSetDescListInvitees = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      correction: desc,
    }));
  };
  const handleSetDescListInvitees1 = (desc: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      root_cause: desc,
    }));
  };
 

  const handleInputChange = (name: string, value: string) => {
    setModuleData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <thead>
      {/* <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No.:', 'Rev No.:', 'Eff. Date:']}
        values={['F-MR-06 ', '03', '01-04-2019']}
        heading="NON-CONFORMANCE REPORT"
      /> */}

      <tr>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '40px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '150px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '50px',
          }}
        ></th>
        <th
          colSpan={1}
          style={{
            padding: '0px',
            margin: '0px',
            border: 'none',
            height: '0px',
            width: '100px',
          }}
        ></th>
      </tr>

   
      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           Non- Conformity Details
          </div>
        </th>
        
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.details_of_nc)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDesc}
                initialContent={moduleData?.details_of_nc}
              />
            </div>
          </td>
        )}
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{ border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           Description of Objective Evidence
          </div>
        </th>
        
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.details_of_desc)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescObj}
                initialContent={moduleData?.details_of_desc}
              />
            </div>
          </td>
        )}
      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={2} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Auditor Sign  
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="auditor_sign"
              type="text"
              value={moduleData?.auditor_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        <th colSpan={1} style={{ border:'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              // borderRight: 'none',
              // borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            Dept. Rep. Sign 
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="dept_rep_sign"
              type="text"
              value={moduleData?.dept_rep_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
        
        
      </tr>
{/* 
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
Root Cause Analysis (Do why-why analysis to identify the root cause)          </div>
        </th>
      </tr> */}

      {/* <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.root_cause)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees}
                initialContent={moduleData?.root_cause}
              />
            </div>
          </td>
        )}
      </tr> */}
      <tr style={{ border: 'none' }}>
      <th align='center' colSpan={5} style={{ borderRight: 'none', borderBottom: 'none' }}>
          <div
            style={{
              // marginLeft: '4px',
              textAlign: 'center',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
            TO BE COMPLITED BY THE AUDITEE 
          </div>
        </th>
        <th
          colSpan={2}
          style={{
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              borderLeft: 'none',
              borderBottom: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{  border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
          Correction: (What is done to solve the specified NC ?)
          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.correction)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees}
                initialContent={moduleData?.correction}
              />
            </div>
          </td>
        )}
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
Root Cause Analysis (Do why-why analysis to identify the root cause)          </div>
        </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.root_cause)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees1}
                initialContent={moduleData?.root_cause}
              />
            </div>
          </td>
        )}
      </tr>
      <tr style={{ border: 'none' }}>
        <th colSpan={7} style={{ borderRight: 'none', border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              borderRight: 'none',
              border: 'none',
              fontSize: '18px',
            }}
          >
Corrective Action (What is done to prevent recurrence?)   
</div>
     </th>
      </tr>

      <tr style={{ border: 'none' }}>
        {isView ? (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap">
              {HTMLReactParser(moduleData?.corr_action)}
            </div>
          </td>
        ) : (
          <td colSpan={7} style={{ border: 'none' }}>
            <div className="tiptap" style={{ columnSpan: 'all' }}>
              <TipTap
                setDesc={handleSetDescListInvitees}
                initialContent={moduleData?.corr_action}
              />
            </div>
          </td>
        )}
      </tr>

      <tr style={{border:'none'}}>
        <th colSpan={2} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          Is there any scope for Horizontal Deployment ?          </div>
        </th>
        <td colSpan={5} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="scope"
              type="text"
              value={moduleData?.scope}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      

      </tr>

      <tr style={{ border: 'none' }}>
        <th colSpan={5} style={{  border: 'none' }}>
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'left',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            TARGET DATE
          </div>
        </th>
        <th
          colSpan={1}
          style={{
            border: 'none',
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              border: 'none',
              fontSize: '18px',
            }}
          >
           
          </div>
        </th>

        <th
          colSpan={1}
          align="left"
          style={{ border: 'none'}}
        >
          {isView ? (
            formatDate(moduleData.target_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.target_date)}
              onChange={(date) => {
                moduleData.target_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
      <th
          colSpan={2}
          style={{
            border:'none'
          }}
        >
          <div
            style={{
              marginLeft: '4px',
              textAlign: 'right',
              paddingTop: '4px',
              paddingBottom: '4px',
              border:'none',
              fontSize: '18px',
            }}
          >
            DATE :
          </div>
        </th>

        <th
          colSpan={2}
          align="left"
          style={{ border:'none' }}
        >
          {isView ? (
            formatDate(moduleData.target_date_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.target_date_date)}
              onChange={(date) => {
                moduleData.target_date_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>

        
        <th colSpan={1} style={{borderRight:'none',border:'none' }}>
          <div style={{marginLeft:'4px', textAlign:'left', paddingTop:'4px', paddingBottom:'4px', borderRight:'none', border:'none', fontSize:'18px'}}>
          HOD Sign
          </div>
        </th>
        <td colSpan={2} style={{ border: 'none' }}>
            
            <TableInput
              isView={false}
              style={{ height: '80px'}}
              name="hod_sign"
              type="text"
              value={moduleData?.hod_sign}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}

            />
          </td>
      </tr>


    </thead>
  );
};

export default THead;
