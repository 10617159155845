import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import THead1 from './THead1';
import THead2 from './THead2';
import { IData } from '../../helpers/interfaces';
import { useState } from 'react';
interface IProps {
  moduleData: IData;
  isView: boolean;
  handleSumbit: any;
  handelDeleteRows: any;
  isOpenPopup: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setModuleData: any;
}

const TController = ({
  moduleData,
  isView,
  handleSumbit,
  handelDeleteRows,
  isOpenPopup,
  setIsOpenPopup,
  setPartIdx,
  setModuleData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead1
          {...{
            moduleData,
            isView,
            setModuleData,
          }}
        />
        <THead
          {...{
            moduleData,
            isView,
            setModuleData,
          }}
        />
        <THead2
          {...{
            moduleData,
            isView,
            setModuleData,
          }}
        />
        {/* <TBody
          {...{
            moduleData,
            isView,
            handleSumbit,
            handelDeleteRows,
            isOpenPopup,
            setIsOpenPopup,
            setPartIdx,
            setRender,
            setModuleData,
          }}
        /> */}
        <TFoot
          moduleData={moduleData}
          render={render}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
